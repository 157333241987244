/* --------------------------------------- */
/* Selectize */
.selectize-control.plugin-drag_drop.multi > .selectize-input > div.ui-sortable-placeholder {
  visibility: visible !important;
  background: #f2f2f2 !important;  
  border: 0 none !important;  
}
.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
  content: '!';
  visibility: hidden;
}
.selectize-dropdown-header {
  position: relative;
  padding: 5px 8px; 
}
.selectize-control {
  position: relative;
}
.selectize-dropdown,
.selectize-input,
.selectize-input input {
  color: #303030;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
  -webkit-font-smoothing: inherit;
}
.selectize-input {   
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
 }
.selectize-input > * {
  vertical-align: baseline;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  font-family: $font-gotham;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  background: none;
}
.selectize-input > input:focus {
  outline: none !important;
}
.selectize-dropdown {
  position: absolute;
  z-index: 20;  
  margin: -1px 0 0 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;  
}
.selectize-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
  font-size: 12px;
  font-family: $font-gotham;
  text-transform: uppercase;
}
.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
  cursor: pointer;
}
.selectize-control.single .selectize-input:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -5px;
  width: 15px;
  height: 7px;
  background: url(../images/header/arrow.gif) no-repeat;
}
.selectize-control.single .selectize-input.dropdown-active:after {  
  background: url(../images/header/arrow1.gif) no-repeat;
}
.selectize-control.single .selectize-input,
.selectize-dropdown.single {
  background-color: white;
}

/* Selectize Contact*/
.block-tab .selectize-control.plugin-drag_drop.multi > .selectize-input > div.ui-sortable-placeholder {
  visibility: visible !important;
  background: #f2f2f2 !important;  
  border: 0 none !important;  
}
.block-tab .selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
  content: '!';
  visibility: hidden;
}
.block-tab .selectize-dropdown-header {
  position: relative;
  padding: 5px 8px; 
}
.block-tab .selectize-control {
  position: relative;
  width: 210px;
  margin-top: 40px;
  display: none;
}
.block-tab .selectize-dropdown,
.block-tab .selectize-input,
.block-tab .selectize-input input {
  color: #303030;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
  -webkit-font-smoothing: inherit;
}
.block-tab .selectize-input {   
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
 }
.block-tab .selectize-input > * {
  vertical-align: baseline;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  color: #fff;
  font-size: 24px;    
  font-family: "GothamBlack", Arial;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 7px solid #fff;
  height: 29px;
  padding: 0 40px 0 10px;
  width: 100%;
}
.block-tab .selectize-input > input {
  display: none; 
}
.block-tab .selectize-dropdown {
  position: absolute;
  z-index: 20;  
  margin: -1px 0 0 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;  
}
.block-tab .selectize-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
  font-size: 12px;
  font-family: $font-gotham;
  text-transform: uppercase;
}
.block-tab .selectize-control.single .selectize-input,
.block-tab .selectize-control.single .selectize-input input {
  cursor: pointer;
}
.block-tab .selectize-control.single .selectize-input:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -15px;
  width: 11px;
  height: 11px;
  background: url(../images/header/arrow-mb.gif) no-repeat;
}
.block-tab .selectize-control.single .selectize-input.dropdown-active:after {  
  background: url(../images/header/arrow1-mb.gif) no-repeat;
}
.block-tab .selectize-control.single .selectize-input {
  background-color: transparent;  
}
.block-tab .selectize-dropdown.single {
  background-color: white;
  padding: 0 10px 5px;
}
