@include font('Georgia Italic', 'GeorgiaItalicphuoc-Italic');
@include font('Gotham-Medium', 'Gotham-Medium');
@include font('Gotham-Book', 'Gotham-Book');
@include font('GothamBlack', 'iCiel-GothamBlack');
@include font('GothamBold', 'GothamBold');
@include font('GothamVNF', 'VNF-Gotham');
@include font('GothamMedium', 'iCiel-Gotham Medium');

html,body {
	max-width: 100%;
	color: #000;
	font-family: $font-gotham-book;
	letter-spacing: -0.5
}

.page {
	background-color: #fff;
	color: #333;
	overflow: hidden;
	min-height: 580px;
	margin: 70px 0 0;
}

.home
.pContactus {
	margin-bottom: 0;
}

.button {
	padding: 10px 30px;
	font-size: 15px;
	font-family: $font-georgia-italic;
	color: white;
	border-radius: 50px;
	border: none;
	background-color: #ff6c34;	

	&:hover {
		background-color: #ffcc31;
		color: #1e1e1e;
		@include transition(0.3s);
		text-decoration: none;
	}
}

.img-center {
	width: 100% !important;
	text-align: center;

	img {
		width: 100%;
	}
}

.btn-top {
	background: url(../images/products/btn-top.gif) no-repeat 0 0;
	width: 83px;
	height: 83px;
	text-align: center;
	font-size: 14px;	
	font-family: $font-georgia-italic;
	color: white;
	position: fixed;
	right: 20px;
	bottom: 55px;
	z-index: 50;
	text-decoration: none;
	display: none;	
	padding: 40px 0 0;
	
	&:hover {
		background-position: -83px 0;
		text-decoration: none;		
	}
}

.js-scroll {
	overflow: hidden;
}

.full {
	width: 100% !important;
}

.no-border {
	border: none !important; 
}

.gsc-control {
	width: 100% !important;
    margin: 100px auto 0;
    max-width: 1200px;
}

form.gsc-search-box, .gsc-tabsArea {
	display: none;
}

.gsc-result-info {
	font-size: 15px;
}

.gs-result .gs-title, .gs-result .gs-title * {
	font-size: 15px;
	color: #ff6c34 !important;	
}

.gs-result .gs-snippet {
	font-size: 13px;
}

.gs-result a.gs-visibleUrl, .gs-result .gs-visibleUrl {
	font-size: 12px;
	margin: 10px 0;
}

.gsc-results .gsc-cursor-box {
	margin-top: 10px;
}

.gsc-results .gsc-cursor-box .gsc-cursor-current-page,
.gsc-results .gsc-cursor-box .gsc-cursor-page {
	font-size: 14px;
}

