/* --------------------------------------- */
/* Dropdown list */

div.dropdown {
  text-align: left;
  font-size: 15px;
  color: #1e1e1e;
 font-family: $font-georgia-italic;
  width: 220px;
 /*  position: absolute;
  right: 92/1400*100%;
  top: 29px; */
  float: right;

  &.closed {

  	ul {
		  margin-top: 0px;
		  border: none;

		  li {
			  height: 0px;
			  border: none;
			}
		}

		.title:after {
		  margin-top: -14px;
		  -moz-transform: rotate(0deg);
		  -ms-transform: rotate(0deg);
		  -webkit-transform: rotate(0deg);
		  transform: rotate(0deg);
		  display: block;
		}
  }

  .title {
	  width: 100%;
	  height: 38px;
	  padding: 10px 0;
	  text-align: center;
	  cursor: pointer;

	  &:after {
		  content: "▾";
		  position: absolute;
		  right: 12px;
		  margin-top: 0;
		  @media screen and (-webkit-min-device-pixel-ratio:0)
			{
				margin-top: -14px;
			}
		  -moz-transform: rotate(180deg);
		  -ms-transform: rotate(180deg);
		  -webkit-transform: rotate(180deg);
		  transform: rotate(180deg);
		}
	}

	ul {
	  overflow: hidden;
	  -moz-transition: margin-top 1s;
	  -o-transition: margin-top 1s;
	  -webkit-transition: margin-top 1s;
	  transition: margin-top 1s;
	  border-top: 1px solid black;
	  padding: 0 20px;
	  background-color: white;
	  z-index: 100;

	  li {
		  width: 100%;
		  height: 46px;
		  text-align: center;
		  overflow: hidden;
		  cursor: pointer;
		  border-top: 1px dashed #c3c3c3;
		  -moz-transition: margin-top 0.5s, height 0.5s;
		  -o-transition: margin-top 0.5s, height 0.5s;
		  -webkit-transition: margin-top 0.5s, height 0.5s;
		  transition: margin-top 0.5s, height 0.5s;

		  &:first-child {
			  border-top: none;
			}

			&:hover {
			  color: #ff6c34;
			}

			&:before {
			  content: " ";
			  display: inline-block;
			  position: relative;
			  height: 46px;
			  margin-top: -18px;
			  -moz-transition: all 0.5s;
			  -o-transition: all 0.5s;
			  -webkit-transition: all 0.5s;
			  transition: all 0.5s;
			}
		}
	}

}

