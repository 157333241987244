/* --------------------------------------- */
/* Header */
header {
	width: 100%;
	height: 73px;
	text-align: center;	
	color: #282828;
	position: fixed;
	top: 0;
	z-index: 11;
	background: url(../images/header/header-loop.png) repeat-x;

	.logo-daithuan {		
		text-indent: -9999px;
		display: block;
		width: 151px; 
		position: absolute;
		left: 60/1400*100%;
		top: 10px;

		img {
			width: 100%;
			float: left;
		}
	}

	nav {
		width: 100%;
		height: 70px;
		padding: 0 0 0 310/1400*100%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;

		ul {
			width: 100%;

			li {
				float: left;
				margin: 0 0 0 1px;

				a {
					font-size: 12px;
					color: #282828;
					font-family: $font-gotham;				
					text-transform: uppercase;
					float: left;
					padding: 29px 20px;

					&.menu-home {
						background: url(../images/header/icon-home.png) no-repeat center 27px;
						width: 19px;
						height: 16px;
						display: block;
						text-indent: -9999px;
						padding: 35px 25px;

						&:hover,
						&.js-active {
							background: url(../images/header/icon-home-hov.png) no-repeat center 27px $text-orange;
							@include transition(0.3s);
						}
					}

					&:hover {
						color: #fff;	
						background-color: $text-orange;	
						text-decoration: none;				
						@include transition(0.3s);
					}
				}
				
				&.js-active,
				&.js-hover {	
					color: #fff;				
					background-color: $text-orange;					

					a {
						color: #fff;

						&.menu-home,
						&.menu-home:hover {
							background: url(../images/header/icon-home-hov.png) no-repeat center 27px $text-orange;
							@include transition(0.3s);
						}						
					}
				}

				.list-menu-sub {			
					position: absolute;
					top: 70px;	
					display: none;
					width: 185px;

					li {
						width: 100%;
						float: left;
						text-align: center;
						margin-left: 0;								

						a {
							font-size: 14px;
							color: white;
							width: 100%;
							padding: 15px 20px;
							background-color: #3d3d3d;
							text-align: left;

							&:hover {
								background-color: $text-orange;
								@include transition(0.5s);
							}
						}

						&.js-active a {
							background-color: $text-orange;
							@include transition(0.5s);
						}
					}
				}

				a:hover + .list-menu-sub {
					display: block;
				}

	 		}
		}
		
	}

	.btn-nav-mobile {
		width: 30px;
		height: 25px;		
		cursor: pointer;
		display: none;
		position: absolute;
		top: 20px;
		right: 10px;

		&.js-active {
			background: url(../images/header/nav-mobile-close.png) no-repeat 5px 2px;

			span {
				display: none;
			}			
		}

		span {
			display: block;
			width: 100%;
			height: 4px;
			margin-bottom: 3px;
			background-color: black;
			border-radius: 5px;
		}
	}

	.nav-mobile {
		position: absolute;
		top: 70px;
		width: 100%;
		z-index: 25;
		display: none;

		li {

			a {
				color: #282828;
				display: block;
				width: 100%;				
				background-color: #eeeeee;
				border: 1px solid white;
				padding: 20px 0;
				position: relative;
				font-size: 16px;
				font-family: $font-gotham;
				text-decoration: none;
				text-transform: uppercase;				
			}

			&:hover,
			&.js-active {

				a {
					background-color: $text-orange;
					border: 1px solid #eeeeee;
					color: white;
				}

				.list-menu-sub {
					display: none;

					li a {
						background: #fff;
						color: #000;
						border: 1px solid #eeeeee;

						&:hover {
							background-color: $text-orange;
							color: white;
						}
					}					
				}
			}

			.list-menu-sub {
					display: none;

					li a {
						background: #fff;
						color: #000;
						border: 1px solid #eeeeee;

						&:hover {
							background-color: $text-orange;
							color: white;
						}
					}					
				}
		}

	}

	.btn-search {
		background: url(../images/header/btn-search.png) no-repeat;
		width: 23px;
		height: 23px;
		position: absolute;
		top: 23px;
		right: 170/1400*100%;		

		&:hover,
		&.js-active {		
			background: url(../images/header/btn-search-act.png) no-repeat;	
		}
	}

	.search {		
		width: 280px;
		height: 70px;
		background-color: #e85720;
		padding: 10px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		position: absolute;
		top: 70px;
		right: 40/1400*100%;
		display: none;

		input {		   
		  	font-size: 15px;
		  	font-weight: normal;
		 	color: #fff;
		 	padding: 17px 10px 17px 20px;
		  	margin: 0 !important;
		  	height: auto;
		  	background-color: #a93203;
		  	border: none;
		  	float: left;
		  	font-family: $font-georgia-italic;
		  	width: 100%;
		  	@include border-radius(20px, true);
		}
	}

}

/*** SELECT ***/
.js-slt-lang {
	width: 53px !important;
	position: absolute !important;
	top: 25px;
	right: 100/1400*100%;
	border: none !important;

	> a {		
		height: 15px !important;				
		line-height: 0 !important;
		background: url(../images/header/arrow.gif) no-repeat right 3px !important;	

		&:hover,
		&.js-active {
			background: url(../images/header/arrow1.gif) no-repeat right 3px !important;
		}	

		> span {
			padding: 0 !important;
		}
	}

	> ul {		
		top: 16px !important;		
	   	margin: 0 !important;
	   	background: transparent !important;

	   	li {
			width: 100% !important;
			margin-left: 0 !important;

			> a {				   	    
			    width: 100%;
			    float: left;	
			    padding: 1px 0 !important;
			    background: transparent !important;

			    &:hover {
			    	background: transparent !important;
			    }		   	
			 }	 
		}
	}
}
