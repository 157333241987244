/**
 * Main CSS
 */

// General imports ------------------------------------------------------------
//
// These imports don't generate CSS, but make defined variables, functions,
// and mixins available for further usage
/* Locale Setting */
$locale: 'en';

@import 'base/config';
@import 'base/reset';
@import 'base/functions';
@import 'base/body';

/** Vendor **/
//@import 'vendor/jquery-ui';

/** Mixins */
@import 'mixin/all';


// Styles ---------------------------------------------------------------------
@import 'module/base';
@import 'module/header';
@import 'module/selectize';
@import 'module/footer';
@import 'module/dropdown';
@import 'module/banner';
@import 'module/aboutus';	
@import 'module/events';
@import 'module/products';
@import 'module/detail';
@import 'module/menuleft';
@import 'module/distributors';
@import 'module/suppliers';
@import 'module/news';
@import 'module/partner';
@import 'module/scrollbar';
@import 'module/contactus';	
@import 'module/faqs';
@import 'module/whatwedo';
@import 'module/responsive';	
@import 'module/vn';
@import 'module/popup';
@import 'module/jalert';

// module styles for each projects import here

@import 'module/mobile';


// IE fix ---------------------------------------------------------------------
@import 'ie';

// Print ----------------------------------------------------------------------
//
// Inline to avoid the request
@import 'module/print';
